import { graphql, useStaticQuery } from "gatsby"

export default function AppDevelopmentData() {
  return useStaticQuery(graphql`
    query queryAppDevelopment {
      seo: sanitySeo(slug: { current: { eq: "app-development" } }) {
        meta_title
        meta_description
        keywords
        meta_image {
          asset {
            url
          }
        }
        alt_tag
      }
      hero: sanityHero(slug: { current: { eq: "app-developer" } }) {
        title
        hints {
          parts
        }
        svgUpload
        isHintsAnimation
        backgroundColor {
          hex
        }
      }
      video: sanityVideoSection(
        slug: { current: { eq: "new-app-developer" } }
      ) {
        description
        slug {
          current
        }
        quote
        title
        hints {
          parts
        }
        backgroundColor {
          hex
        }
        video_link
        isHintsAnimation
      }
      features: sanityFeatures(slug: { current: { eq: "new-app-developer" } }) {
        id
        hints {
          parts
        }
        title
        slide
        features {
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      features2: sanityFeatures(
        slug: { current: { eq: "app-development-our-formula-for-success" } }
      ) {
        features {
          backgroundColor {
            hex
          }
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        id
        hints {
          parts
        }
        title
        slide
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      logoSlider: sanityLogoSlider(
        slug: { current: { eq: "logo-slider-app-development" } }
      ) {
        title
        backgroundColor {
          hex
        }
        logos {
          logo {
            asset {
              url
            }
          }
        }
      }
      features3: sanityFeatures(slug: { current: { eq: "what-we-can-help" } }) {
        id
        hints {
          parts
        }
        title
        slide
        features {
          title
          icon {
            asset {
              url
            }
          }
          backgroundColor {
            hex
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      action: sanityCallAction(slug: { current: { eq: "need-help" } }) {
        title
        button
        description
        image {
          asset {
            url
          }
        }
        background {
          asset {
            url
          }
        }
        backgroundColor {
          hex
        }
        target
      }
      testimonial: sanityTestimonials(slug: { current: { eq: "light" } }) {
        title
        testimonials {
          author {
            name
            position
            image {
              asset {
                url
              }
            }
          }
          text
        }
        shadowBackgroundColor {
          hex
        }
        cardBackgroundColor {
          hex
        }
        backgroundColor {
          hex
        }
      }
      faq: sanityFaq(slug: { current: { eq: "app-development-faq" } }) {
        title
        backgroundColor {
          hex
        }
        questions {
          response
          faq
          id
        }
      }
      services: sanityFeatures(slug: { current: { eq: "why-choose-devwiz" } }) {
        id
        hints {
          parts
        }
        slide
        title
        features {
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
    }
  `)
}
