// extracted by mini-css-extract-plugin
export var appSlider = "slide-module--app-slider--neDKj";
export var book = "slide-module--book--3sDG2";
export var content = "slide-module--content--2_ae2";
export var defaultContainer = "slide-module--default-container--Ukix_";
export var featureSlider = "slide-module--feature-slider--1r63v";
export var innerPagesSlider = "slide-module--inner-pages-slider--3yJ4-";
export var leftAppSlide = "slide-module--left-app-slide--2MrU3";
export var logoSlider = "slide-module--logo-slider--1KPoz";
export var phoneAppSelect = "slide-module--phone-app-select--1cU5A";
export var phoneCaseSelect = "slide-module--phone-case-select--1nr8U";
export var rightAppSlide = "slide-module--right-app-slide--18Zx3";
export var slickActive = "slide-module--slick-active--2BLRq";
export var slickArrow = "slide-module--slick-arrow--2GZdx";
export var slickCenter = "slide-module--slick-center--3rXrH";
export var slickDots = "slide-module--slick-dots--3Z_uj";
export var slickNext = "slide-module--slick-next--1hM0i";
export var slickPrev = "slide-module--slick-prev--1RA0i";
export var slickSlide = "slide-module--slick-slide--egSnE";
export var slickSlider = "slide-module--slick-slider--M0xMm";
export var slickTrack = "slide-module--slick-track--3DTVY";
export var solvingSlider = "slide-module--solving-slider--3Jgnu";
export var subtitle = "slide-module--subtitle--1WQ24";
export var text = "slide-module--text--1nTom";
export var title = "slide-module--title--lOrfI";