import { navigate } from "gatsby"
import React from "react"
import * as styles from "./slide.module.scss"
export default function Slide(props) {
  const { content } = props

  const resolve = action => {
    if (action == null) return
    console.log(action)
    switch (action) {
      case "quote": {
        props.setBook(true)
        break
      }
      case "estimate": {
        props.setFreeEstimationPopup(true)
        break
      }
      case "navigate": {
        if (content.link == null) return
        navigate(`/${content.link}`)
        break
      }
    }
  }
  return (
    <div className={styles.content}>
      <h3 className={styles.title}>{content?.title}</h3>
      <h6 className={styles.subtitle}>Problem</h6>
      <p className={styles.text}>{content?.problem}</p>
      <h6 className={styles.subtitle}>The DevWiz Solution</h6>
      <p className={styles.text}>{content?.solution}</p>
      <button onClick={() => resolve(content?.action)} className={styles.book}>
        {content?.button}
      </button>
    </div>
  )
}
