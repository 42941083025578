import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Layout from "../components/Layout"
import Hero from "../sections/hero/hero"
import ClientExp from "../sections/clients_exp/clients_exp"
import Video from "../sections/video_section/Video"
import Features from "../sections/features/features"
import Services from "../sections/services/services"
import CallToAction from "../sections/call_to_action/call_to_action"
import Faq from "../sections/faq/faq"
import Slides from "../sections/slides/slides"
import Blogs from "../sections/latest_blogs/latest_blogs"
import LogoSlides from "../sections/logo_slides/logo_slides"
import * as hero_styles from "../sections/hero/hero_index.module.scss"
import * as client_exp_styles from "../sections/clients_exp/clients_exp.module.scss"
import * as video_styles from "../sections/video_section/video_index.module.scss"
import * as features_inline_5 from "../sections/features/features_inline_5.module.scss"
import * as icon_background from "../sections/features/icon_background.module.scss"
import * as feature_inline_style from "../sections/features/features_inline_4.module.scss"
import * as services_styles from "../sections/services/services.module.scss"
import * as square_call from "../sections/call_to_action/action_square.module.scss"
import * as book_call from "../sections/call_to_action/book.module.scss"
import * as faq_styles from "../sections/faq/faq.module.scss"
import * as slide_styles from "../sections/slides/slides.module.scss"
import * as logo_slide_styles from "../sections/logo_slides/logo_slides.module.scss"
import AppDevelopmentData from "../utils/appDevelopment-queries"
import { getSeo } from "../utils/seo"

export default function AppDevelopment() {
  const {
    hero,
    video,
    features,
    features2,
    logoSlider,
    features3,
    action,
    testimonial,
    faq,
    services,
  } = AppDevelopmentData()
  return (
    <Layout navBG={hero?.backgroundColor ? hero.backgroundColor.hex : ""}>
      {/* <Seo
        title={developer.title}
        keywrods={developer.keywrods}
        description={developer.description}
      /> */}
      <Hero
        hero={hero}
        styles={hero_styles}
        nextSection={video.slug?.current}
      />
      <Video content={video} styles={video_styles} />
      <Features features={features} styles={feature_inline_style} />
      <Features features={features2} styles={icon_background} />
      <LogoSlides content={logoSlider} styles={logo_slide_styles} />
      <Features features={features3} styles={features_inline_5} />
      <CallToAction call={action} styles={book_call} />
      <ClientExp content={testimonial} styles={client_exp_styles} />
      <Faq faq={faq} styles={faq_styles} />
      <Services content={services} styles={services_styles} />

      {/* <Slides styles={slide_styles} />
      <CallToAction call={process} styles={square_call} arrow={true} />
      <Features features={formula} styles={icon_background} />
      <Blogs /> */}
    </Layout>
  )
}

export const Head = () => {
  const { seo } = AppDevelopmentData()
  return getSeo(seo)
}
