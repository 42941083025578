// extracted by mini-css-extract-plugin
export var aboutContainer = "features_inline_5-module--about-container--2tApA";
export var aboutContent = "features_inline_5-module--about-content--3Wk3t";
export var appSlider = "features_inline_5-module--app-slider--1TP0N";
export var contentWrapper = "features_inline_5-module--content-wrapper--2bJIX";
export var defaultContainer = "features_inline_5-module--default-container--1Y56K";
export var featureSlider = "features_inline_5-module--feature-slider--mnibC";
export var features = "features_inline_5-module--features--3D48a";
export var featuresItem = "features_inline_5-module--features-item--pDl2u";
export var infoBoxImage = "features_inline_5-module--info-box-image--24RqV";
export var innerPagesSlider = "features_inline_5-module--inner-pages-slider--28AOp";
export var leftAppSlide = "features_inline_5-module--left-app-slide--2RPZb";
export var logoSlider = "features_inline_5-module--logo-slider--3AA4q";
export var numbers = "features_inline_5-module--numbers--ABvp-";
export var phoneAppSelect = "features_inline_5-module--phone-app-select--1WI4A";
export var phoneCaseSelect = "features_inline_5-module--phone-case-select--2Dp_e";
export var rightAppSlide = "features_inline_5-module--right-app-slide--3kznD";
export var slickActive = "features_inline_5-module--slick-active--23-tc";
export var slickArrow = "features_inline_5-module--slick-arrow--2R_rG";
export var slickCenter = "features_inline_5-module--slick-center--2AQu0";
export var slickDots = "features_inline_5-module--slick-dots--2hM6C";
export var slickNext = "features_inline_5-module--slick-next--2AL6z";
export var slickPrev = "features_inline_5-module--slick-prev--3MSsU";
export var slickSlide = "features_inline_5-module--slick-slide--4Qj4l";
export var slickSlider = "features_inline_5-module--slick-slider--2xTYO";
export var slickTrack = "features_inline_5-module--slick-track--3PU7U";
export var solvingSlider = "features_inline_5-module--solving-slider--rrVHq";
export var title = "features_inline_5-module--title--2hyuF";
export var titleWrapper = "features_inline_5-module--title-wrapper--1l-mD";