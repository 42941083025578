import React from "react"
import Slide from "../../components/slide/slide"
import { replaceSpacing } from "../../utils/functions"
import SliderActioner from "../../components/sliderActioner/sliderActioner"
import {
  animationSlideUp,
  animationSlideRight,
  animationSlideLeft,
  animationFade,
  animationZoomIn,
} from "../../utils/scroll-animation"
import Hints from "../../common/hints/hints"

export default function Slides(props) {
  const { content, styles } = props

  var sliderSetting = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
  }

  return (
    <section
      style={{
        backgroundColor: content?.backgroundColor
          ? content.backgroundColor.hex
          : "",
      }}
      className={styles.slide}
    >
      <div className="default-container">
        <div className={styles.slideContainer}>
          <div className={styles.titleWrapper}>
            <h2 className={styles.title} {...animationSlideRight()}>
              {replaceSpacing(content?.title)}
            </h2>
            <Hints
              hints={content?.hints}
              isAnimation={false}
              typingBackground={
                content?.backgroundColor
                  ? content.backgroundColor.hex
                  : "#271b4f"
              }
            />
          </div>
          <div className={styles.contentWrapper} {...animationFade()}>
            <div className={styles.slides}>
              <SliderActioner
                sliderClass="solving-slider"
                sliderSetting={sliderSetting}
              >
                {content.slides.map((slide, index) => (
                  <Slide
                    key={index}
                    content={slide}
                    book={props.book}
                    setBook={props.setBook}
                    freeEstimationPopup={props.freeEstimationPopup}
                    setFreeEstimationPopup={props.setFreeEstimationPopup}
                  />
                ))}
              </SliderActioner>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
